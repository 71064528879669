@import "./../../styles/mixins"
@import "./../../styles/variables"

@import "~bootstrap/scss/bootstrap"
@import "~bootstrap/scss/_variables.scss"
@import "~bootstrap/scss/_mixins.scss"
 
.calendar-container
  display: flex
  flex-wrap: wrap 
  position: relative

.calendar-spinner-container 
  position: absolute
  top: 0
  left: 0
  bottom: 0 
  right: 2rem // slight adjustment due to weird side

.calendar-loading-spinner
  margin: auto
  margin-top: 20rem

// 100% / 7
.seven-a-row
  display: flex
  min-width: 14.2857142857%

.day-item
  height: 7rem
  border-top: solid 1px grey
  border-left: solid 1px grey
  @include media-breakpoint-up(lg) 
    height: 11rem

.day-border-right
  border-right: solid 1px grey
  
.day-border-bottom
  border-bottom: solid 1px grey

.current-month
  font-weight: bold

.other-month
  font-weight: bold
  position: relative
  &:after
    position: absolute
    content: ""
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: $other-month-overlay
    pointer-events: none  

.add-btn 
  color: white
  background: $primary-button-gradient
  border: solid 0.15rem white
  &:hover 
    color: $primary-button-color
    background: white
    border: solid 0.15rem $primary-button-color

.create-booking-btn
  color: white
  background: $primary-button-gradient
  border: solid 0.15rem white
  &:hover 
    color: $primary-button-color
    background: white
    border: solid 0.15rem $primary-button-color

.left-arrow
  display: flex
  width: 2rem
  cursor: pointer
  &:after
    content: ""
    height: 1.25rem
    width: 1.25rem
    border-left: solid 0.15rem black
    border-bottom: solid 0.15rem black
    transform: rotate(45deg)
    margin-top: auto
    margin-bottom: auto

.right-arrow
  display: flex
  width: 2rem
  cursor: pointer
  &:after
    content: ""
    height: 1.25rem
    width: 1.25rem
    border-left: solid 0.15rem black
    border-bottom: solid 0.15rem black
    transform: rotate(-135deg)
    margin-top: auto
    margin-bottom: auto

.legend-item 
  padding-left: 1.4rem
  position: relative
  &:after
    content: ""
    position: absolute
    height: 1rem
    width: 1rem
    left: 0
    top: 0.4rem
    border-radius: 1rem

.legend-item-orange 
  &:after
    background: $room1-color

.legend-item-green
  &:after
    background: $room2-color

.legend-item-yellow 
  &:after
    background: $room3-color

.legend-item-blue
  &:after
    background: $room4-color

.room-booking
  height: 1rem
  padding-left: 0
  padding-right: 0
  margin-bottom: 0.25rem
  &.room-morn
    margin-right: 0.2rem
    border-radius: 0 1rem 1rem 0
  &.room-arvo
    margin-left: 0.2rem
    border-radius: 1rem 0 0 1rem
  @include media-breakpoint-up(lg) 
    height: 2rem
    &.room-morn
      margin-right: 0.5rem
      border-radius: 0 2rem 2rem 0
    &.room-arvo
      margin-left: 0.5rem
      border-radius: 2rem 0 0 2rem

.room-hover
  border-top: 0.2rem solid black 
  border-bottom: 0.2rem solid black
  &.room-morn
    border-right: 0.2rem solid black
  &.room-arvo
    border-left: 0.2rem solid black

.room-1.active
  &.pending
    background: repeating-linear-gradient(135deg, $room1-color, $room1-color 10px, $room1-lighter 10px, $room1-lighter 20px)
  &.approved
    background: $room1-color
  
.room-2.active
  &.pending
    background: repeating-linear-gradient(135deg, $room2-color, $room2-color 10px, $room2-lighter 10px, $room2-lighter 20px)
  &.approved
    background: $room2-color

.room-3.active
  &.pending
    background: repeating-linear-gradient(135deg, $room3-color, $room3-color 10px, $room3-lighter 10px, $room3-lighter 20px)
  &.approved
    background: $room3-color

.room-4.active
  &.pending
    background: repeating-linear-gradient(135deg, $room4-color, $room4-color 10px, $room4-lighter 10px, $room4-lighter 20px)
  &.approved
    background: $room4-color
