$primary-button-color: #255dec
$primary-button-darker: #094d92
$primary-button-gradient: linear-gradient(90deg, $primary-button-darker, $primary-button-color)

$room1-color: #FB4D3D
$room1-lighter: #fa7e73

$room2-color: #03CEA4
$room2-lighter: #5cfad9

$room3-color: #EAC435
$room3-lighter: #f7e18b

$room4-color: #74A3F1
$room4-lighter: #a7c3f2

$other-month-overlay: #9f9f9f5e