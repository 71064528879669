@import "./../../styles/variables"

@import "~bootstrap/scss/bootstrap"
@import "~bootstrap/scss/_variables.scss"
@import "~bootstrap/scss/_mixins.scss"

.account-pages-bg
  min-height: 100vh
  width: 100% 
  background: linear-gradient(#DCDCDC, #F5F4F4 50%)

.account-container
  padding-top: 5rem
  padding-bottom: 5rem
  @include media-breakpoint-up(lg) 
    padding-top: 15vh

.account-seperator
  background-color: black
  border: 0.01rem solid black
  content: ""
  margin-top: 0.8rem

.account-seperator-vertical
  margin: auto
  width: 0.1rem
  margin-top: 5%
  height: 85%
  background-color: black
  border: 0.01rem solid black
  content: ""

.account-input
  padding: 1rem 1.5rem 1rem 1.5rem
  border: none
  margin-top: 0.8rem
  width: 100%

.account-filled-button
  padding: 0.5rem 2rem 0.5rem 2rem
  font-size: 2rem
  border: none
  background: $primary-button-gradient
  color: white
  text-decoration: none
  &:hover
    color: white
    background: $primary-button-color

.account-outlined-button
  display: inline-block
  padding: 0.5rem 2rem 0.5rem 2rem
  font-size: 2rem
  border: 0.1rem solid $primary-button-darker
  color: $primary-button-darker
  background: white
  text-decoration: none
  &:hover
    border: 0.1rem solid $primary-button-color
    color: $primary-button-color

.refresh-pressed 
  color: black
  &:hover
    color: black



