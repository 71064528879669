.admin-table
  border: solid 2px black
  margin-left: auto
  margin-right: auto
  margin-top: 2rem

  thead, tbody, tfoot, tr, td, th
    border: solid 2px black

  td, th
    padding: 5px

