@import "~bootstrap/scss/bootstrap"
@import "~bootstrap/scss/_variables.scss"
@import "~bootstrap/scss/_mixins.scss"

// $xl: 576px
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px)

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) 

// Large devices (desktops, 992px and up)
@media (min-width: 992px)

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px)

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px)
  
body
  margin: 0
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  height: 100%
  width: 100%
  // min-width: 200px

code
  font-family: source-code-pro Menlo Monaco Consolas "Courier New" monospace

.full-page-bg
  height: 100vh
  width: 100% 
  background: linear-gradient(#DCDCDC, #F5F4F4 2%)

.form-error
  color: red
  font-size: 1rem

// .form-error::before 
//   display: inline
//   content: "⚠ "



