@import "./../styles/variables";

.nav-font {
  color: #000000;
  &:hover {
    color: $primary-button-color !important;
  }
}

.navbar-brand {
  position: relative;
}

.nav-btn {
  color: white !important;
  background: $primary-button-gradient;
  border: solid 0.15rem white;
  &:hover {
    color: $primary-button-color !important;
    background: white !important;
    border: solid 0.15rem $primary-button-color !important;
  }
}

.nav-btn-selected {
  background: #9e9e9e;
}

.nav-selected {
  &:after {
    content: "";
    position: absolute;
    width: 70%;
    height: 0.25rem;
    background: $primary-button-gradient;
    bottom: 0rem;
    border-radius: 0.4rem;
    left: 15%;
  }
}

.navbar-welcome {
  padding-top: 1rem;
}
